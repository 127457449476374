<template>
	<div>
		<div class="row my-4">
			<h1 class="col-sm-12 text-right">Patrimonios</h1>
		</div>

		<DataGrid :config="dataGridConfig" :data="patrimonios" :select="seleccionar" @actions="dataGridActions">
			<div class="row">
				<div class="col-sm-2" v-if="$auth.can('administrador_de_garantias','crear_patrimonio')"><button class="btn secondary-btn" @click="modal_flag = 1">Crear patrimonio</button></div>
				<div class="col-sm-2" v-if="$auth.can('administrador_de_garantias','editar_patrimonio')"><button class="btn complementary-btn" @click="editar_patrimonio">Editar patrimonio</button></div>
				<div class="col-sm-2" v-if="$auth.can('administrador_de_garantias','listar_patrimonios')"><button class="btn complementary-principal-btn" @click="ver_patrimonio">Ver patrimonio</button></div>
				<div class="col-sm-2"><button class="btn warning-btn" @click="obtener_patrimonios">Recargar</button></div>
			</div>
		</DataGrid>

		<Modal v-if="modal_flag != 0" :options="{width: '90vw',name:'patrimonio_modal',close:true}" @close="cancelar_patrimonio">
			<div class="title">{{ generar_titulo }}</div>
			<div class="body">
				<div class="row form-group">
					<label for="patrimonio.usuario_id" class="col-form-label col-sm-3 col-md-2">Cliente</label>
					<div class="col-sm-8">
						<select v-model="patrimonio.usuario_id" name="patrimonio.usuario_id" id="patrimonio.usuario_id" class="form-control">
							<option v-for="usuario in usuarios" :value="usuario.id">{{ usuario.nombre }}</option>
						</select>
					</div>
					<div class="col-sm-2"><button class="btn secondary-btn" @click="modal_usuario_seleccionado=true" :disabled="modal_flag == 3">Buscar</button></div>
				</div>
				<div class="row form-group">
					<label for="patrimonio.descripcion" class="col-form-label col-sm-3 col-md-2">Descripción</label>
					<div class="col-sm-3 col-md-4"><input v-model="patrimonio.descripcion" type="text" class="form-control" name="patrimonio.descripcion" id="patrimonio.descripcion"/></div>
					<label for="patrimonio.valor" class="col-form-label col-sm-3 col-md-2">Valor</label>
					<div class="col-sm-3 col-md-4">
						<InputCompuesto v-model="patrimonio.valor" label="$" name="patrimonio.valor" id="patrimonio.valor" :formats="['moneyFormat']"/>
					</div>
				</div>
				<div class="row form-group">
					<label for="patrimonio.superficie" class="col-form-label col-sm-3 col-md-2">Superficie</label>
					<div class="col-sm-3 col-md-4">
						<InputCompuesto v-model="patrimonio.superficie" label_right="m2" name="patrimonio.superficie" id="patrimonio.superficie" />
					</div>
					<label for="patrimonio.en_garantia" class="col-form-label col-sm-3 col-md-2">En garantia</label>
					<div class="col-sm-3 col-md-4"><SwitchBPB v-model="patrimonio.en_garantia" id="patrimonio.en_garantia" name="patrimonio.en_garantia" textoff="No" texton="Sí" value="1" /></div>
				</div>
				<div class="row form-group">
					<label for="patrimonio.calle" class="col-form-label col-sm-3 col-md-2">Calle</label>
					<div class="col-sm-3 col-md-4"><input v-model="patrimonio.calle" class="form-control" type="text" name="patrimonio.calle" id="patrimonio.calle"/></div>
					<label for="patrimonio.municipio" class="col-form-label col-sm-3 col-md-2">Municipio</label>
					<div class="col-sm-3 col-md-4"><input v-model="patrimonio.municipio" class="form-control" type="text" name="patrimonio.municipio" id="patrimonio.municipio"/></div>
				</div>
				<div class="row form-group">
					<label for="patrimonio.cp" class="col-form-label col-sm-3 col-md-2">CP</label>
					<div class="col-sm-3 col-md-4"><input v-model="patrimonio.cp" class="form-control" type="text" name="patrimonio.cp" id="patrimonio.cp"/></div>
					<label for="patrimonio.estado" class="col-form-label col-sm-3 col-md-2">Estado</label>
					<div class="col-sm-3 col-md-4">
						<select v-model="patrimonio.estado" name="patrimonio.estado" id="patrimonio.estado" class="form-control">
							<option v-for="estado in estados" :key="estado.id" :value="estado.valor">{{ estado.nombre }}</option>
						</select>
					</div>
				</div>
				<div class="row form-group">
					<label for="patrimonio.pais" class="col-form-label col-sm-3 col-md-2">Pais</label>
					<div class="col-sm-3 col-md-4"><input v-model="patrimonio.pais" class="form-control" type="text" name="patrimonio.pais" id="patrimonio.pais"/></div>
					<label for="patrimonio.numero_escrituras" class="col-form-label col-sm-3 col-md-2">Número escrituras</label>
					<div class="col-sm-3 col-md-4"><input v-model="patrimonio.numero_escrituras" class="form-control" type="text" name="patrimonio.numero_escrituras" id="patrimonio.numero_escrituras"/></div>
				</div>
				<div class="row form-group">
					<label for="patrimonio.fecha_escrituras" class="col-form-label col-sm-3 col-md-2">Fecha escrituras</label>
					<div class="col-sm-3 col-md-4"><input v-model="patrimonio.fecha_escrituras" class="form-control" type="date" name="patrimonio.fecha_escrituras" id="patrimonio.fecha_escrituras"/></div>
					<label for="patrimonio.estatus" class="col-form-label col-sm-3 col-md-2">Estatus</label>
					<div class="col-sm-3 col-md-4">
						<select name="patrimonio.estatus" id="patrimonio.estatus" class="form-control">
							<option value="1">Activo</option>
							<option value="0">Inactivo</option>
						</select>
					</div>
				</div>
			</div>
			<div v-if="modal_flag != 3" class="footer">
				<div class="row">
					<div class="col-sm-2 offset-sm-8"><button class="btn secondary-btn" @click="guardar_patrimonio">{{ generar_titulo }} </button></div>
					<div class="col-sm-2"><button class="btn danger-btn" @click="cancelar_patrimonio">Cancelar</button></div>
				</div>
			</div>
		</Modal>

		<Modal v-if="modal_usuario_seleccionado" @close="cancelar_buscar_usuario">
			<div class="title">Buscar cliente</div>
			<div class="body">
				<div class="row form-group">
					<label for="usuario.nombre" class="col-sm-3 col-form-label">Nombre</label>
					<div class="col-sm-9"><input ref="usuario_nombre" type="text" name="usuario.nombre" id="usuario.nombre" class="form-control" @keypress="buscar_usuario" placeholder="-- Coincidencia a buscar --"></div>
				</div>
				<div class="row form-group">
					<label for="" class="col-sm-3 col-form-label">Coincidencias</label>
					<div class="col-sm-9">
						<select v-model="usuario_seleccionado" name="" id="" class="form-control" size="10">
							<option v-for="usuario in usuarios_buscados" :value="usuario.id">{{ usuario.nombre }}</option>
						</select>
					</div>
				</div>
			</div>
			<div class="footer">
				<div class="row">
					<div class="col-sm-2 offset-sm-8">
						<button class="btn secondary-btn" @click="seleccionar_usuario">Agregar</button>
					</div>
					<div class="col-sm-2">
						<button class="btn danger-btn" @click="cancelar_buscar_usuario">Cancelar</button>
					</div>
				</div>
			</div>
		</Modal>
	</div>
</template>

<script type="text/javascript">
	import DataGrid from '@/components/DataGridV2'
	import Modal from '@/components/Modal'
	import Checkbox from '@/components/Form/Checkbox'
	import SwitchBPB from '@/components/Form/Switch'
	import InputCompuesto from '@/components/Form/InputCompuesto'

	import api from '@/apps/garantias/api/patrimonios'
	import apiUsuarios from '@/apps/garantias/api/usuarios'
	import apiOtros from '@/apps/garantias/api/otros'

	export default {
		components: {
			DataGrid, Modal, Checkbox, InputCompuesto, SwitchBPB
		}
		,data: function() {
			return {
				dataGridConfig: {
					name: 'patrimonios'
					,cols: {
						'usuario.nombre': 'Cliente'
						,descripcion: 'Descripción'
						,valor: 'Valor'
						,superficie: 'Superficie (m2)'
					}
					,paginator: {
						pagina_actual: 1
						,total_registros: 1
						,registros_por_pagina: 20
					}
					,mutators: {
						valor: function(val, row, vue) {
							return '$'+vue.$helper.moneyFormat(val);
						}
						,superficie: function(val) {
							return val+' m2';
						}
					}
				}
				,options: {
					page: 1
					,order_col: 'id'
					,order_dir: 'desc'
					,limit: 20
					,filters: []
				}
				,seleccionar: false
				,seleccionadas: []
				,patrimonios: []
				,modal_flag: 0
				,patrimonio: {
					usuario_id: null
					,id_patrimonio_protrust: null
					,descripcion: null
					,valor: null
					,superficie: null
					,en_garantia: null
					,calle: null
					,municipio: null
					,cp: null
					,estado: null
					,pais: null
					,numero_escrituras: null
					,fecha_escrituras: null
					,notario: null
					,fecha_registro: null
				}
				,usuarios: []
				,usuarios_buscados: []
				,usuario_seleccionado: null
				,modal_usuario_seleccionado: false
				,estados: []
			}
		}
		,mounted: function() {
			this.obtener_usuarios();
			this.obtener_estados();
		}
		,methods: {
			dataGridActions: function(tipo, data) {
				if (tipo == 'options') {
					this.options = data;
					this.obtener_patrimonios();
				}else {
					this.seleccionadas = data;
					this.seleccionar = true;
				}
			}
			,obtener_patrimonios: async function() {
				try {
					let res = (await api.obtener_patrimonios(this.options)).data;

					this.patrimonios = res.data;
					this.dataGridConfig.paginator.pagina_actual = res.current_page;
					this.dataGridConfig.paginator.total_registros = res.total;
					this.dataGridConfig.paginator.registros_por_pagina = parseInt(res.per_page);
				}catch(e) {
					this.$log.info('err',e);
					this.$helper.showAxiosError(e,'Error');
				}
			}
			,obtener_usuarios: async function() {
				try {
					this.usuarios = (await apiUsuarios.obtener_usuarios_todos()).data;
				}catch(e) {
					this.$log.info('error',e);
					this.$helper.showAxiosError(e,'Error');
				}
			}
			,obtener_estados: async function() {
				try {
					this.estados = (await apiOtros.obtener_catalogo(3)).data.opciones;
				}catch(e) {
					this.$log.info('error',e);
					this.$helper.showAxiosError(e,'Error');
				}
			}
			,editar_patrimonio: function() {
				if (this.seleccionadas.length == 0)
					return this.$helper.showMessage('Error','Debes seleccionar un patrimonio a editar','error','alert');

				this.patrimonio = this.seleccionadas[0];

				this.modal_flag = 2;
			}
			,ver_patrimonio: function() {
				if (this.seleccionadas.length == 0)
					return this.$helper.showMessage('Error','Debes seleccionar un patrimonio a editar','error','alert');

				this.patrimonio = this.seleccionadas[0];

				this.modal_flag = 3;

				setTimeout(() => {
					let modal = document.getElementsByClassName('bpb_modal')[0];
					this.$log.info('modal', modal);

					modal.querySelectorAll('input:not([type="button"]), select').forEach(input => {
						input.disabled = true;
					});

				},100);
			}
			,guardar_patrimonio: async function() {
				try {
					if (this.modal_flag == 1) {
						this.patrimonio = (await api.crear_patrimonio(this.patrimonio)).data;

						this.cancelar_patrimonio();
						this.obtener_patrimonios();
					}else
						this.patrimonio = (await api.editar_patrimonio(this.patrimonio.id, this.patrimonio)).data;
				}catch(e) {
					this.$log.info('error',e);
					this.$helper.showAxiosError(e,'Error');
				}
			}
			,cancelar_patrimonio: function() {
				this.patrimonio = {
					usuario_id: null
					,id_patrimonio_protrust: null
					,descripcion: null
					,valor: null
					,superficie: null
					,en_garantia: null
					,calle: null
					,municipio: null
					,cp: null
					,estado: null
					,pais: null
					,numero_escrituras: null
					,fecha_escrituras: null
					,notario: null
					,fecha_registro: null
				}

				this.seleccionar = false;

				this.modal_flag = 0;
			}
			,buscar_usuario: async function() {
				try {
					let buscar = this.$refs.usuario_nombre.value;

					if (buscar == '')
						return;

					this.usuarios_buscados = (await apiUsuarios.buscar_usuario(buscar)).data;
				}catch(e) {
					this.$log.info('error',e);
					this.$helper.showAxiosError(e,'Error');
				}
			}
			,seleccionar_usuario: function() {
				if (!this.usuario_seleccionado)
					return this.$helper.showMessage('Error','Tienes que seleccionar un cliente','error','alert');

				this.patrimonio.usuario_id = this.$helper.clone(this.usuario_seleccionado);
				this.$log.info('usuario_id', this.patrimonio.usuario_id);

				this.cancelar_buscar_usuario();
			}
			,cancelar_buscar_usuario: function() {
				this.usuario_seleccionado = null;
				this.usuarios_buscados = [];
				this.$refs.usuario_nombre.value = null;

				this.modal_usuario_seleccionado = false;
			}
		}
		,computed: {
			generar_titulo: function() {
				switch(this.modal_flag) {
					case 1:
						return 'Crear patrimonio';
						break;
					case 2:
						return 'Modificar patrimonio';
						break;
					case 3:
						return 'Patrimonio '+this.patrimonio.id;
						break;
				}
			}
		}
	}
</script>

<style lang="scss">
	.col-form-label {
		background-color: #E6E6E6;
	}

	table {
		width: 100%;

		tr {
			th {
				background-color: #4C4C4C;
				color: #fff;
				padding: 5px 10px;
			}

			td {
				padding: 5px 8px;
			}
		}

		tr:nth-child(2n+1) {
			background-color: #E6E6E6;
		}
	}

	.icon-eliminar {
		width: 20px;
	}
</style>